import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { lazy, useEffect, useState, Suspense, useContext } from 'react';

import { devConsoleLog } from '../utils/utilityFunctions';
import { getBaseUrl, getRowNumber } from '../utils/userConfig';
import BusinessOnboardFee from '../OnbaordFeeFlow/BusinessFee/BusinessOnboardFee';
import { ContactWithAffiliate, DocumentVerificationPending, InActiveUser } from '../Components/Pages/ContactWithAffiliate';
import FreedomGifLoader from '../Components/SharedComponent/FreedomGifLoader/FreedomGifLoader';
import useTransactionFee from '../CustomHooks/useTransactionFee';
import { ColorModeContext } from '../store';
import { updateUserThemeOther } from '../features/user/userSlice';
import { freedomUserType } from '../FreedomConstants/freedomConstants';
import TraderPluginPage from '../Components/Pages/TraderPluginPage';

const NotAccessible = lazy(() => import('../Components/Pages/NotAccessible'));
const Main = lazy(() => import('../Main'));
const OnBoardMainScreen = lazy(() => import('../Lightonboard/OnBoardMainScreen'));
const OnBoardFeeLoading = lazy(() => import('../OnbaordFeeFlow/OnBoardFeeLoading'));
const OnBoardSelection = lazy(() => import('../Lightonboard/OnBoardSelection'));

const baseUrl = getBaseUrl();

const FlowControl = () => {
  const dispatch = useDispatch();
  const {
    colorMode: { toggleMode, mode }
  } = useContext(ColorModeContext);
  const user = useSelector((state) => state.user.user);
  const { accessToken, activeUser, parentUserLeg, parentUserId, rowNumber, userType, traderType, onboardingCompleted, isCodeValid: isValidInvitationCode } = user;
  const { themeOther } = useSelector((state) => state.user.user);
  const userother = useSelector((state) => state.user.user);
  useTransactionFee();

  const [localStates, setLocalStates] = useState({
    userStatus: 0,
    ledgerTransaction: false,
    loading: true
  });

  /**
   * This function should only call in case of company profile and the response should be modified e.g true or false
   */
  const getStatusAndLedgerFee = async () => {
    await axios
      .get(`${baseUrl}userinvitations?$filter=invitedUserId eq @UserId&$select=Id,Status`, {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      .then((res) => {
        if (res.data.length > 0) {
          setLocalStates((prevstates) => ({
            ...prevstates,
            userStatus: res.data[0].Status
          }));
        }
      });

    await axios
      .get(`${baseUrl}ledgertransactions?$filter=createdByUserId eq @UserId`, {
        headers: { Authorization: 'Bearer ' + accessToken }
      })
      .then((res) => {
        if (res.data.length > 0) {
          setLocalStates((prevstates) => ({
            ...prevstates,
            ledgerTransaction: true
          }));
        }
      });

    setLocalStates((prevstates) => ({
      ...prevstates,
      loading: false
    }));
  };
  useEffect(() => {
    devConsoleLog('flow control');
    getStatusAndLedgerFee();
  }, []);

  useEffect(() => {
    devConsoleLog('mode,theme,userother', mode, themeOther);
    if (themeOther === 'light') {
      toggleMode('light');
      dispatch(updateUserThemeOther('light'));
    } else {
      toggleMode('dark');
      dispatch(updateUserThemeOther('dark'));
    }
  }, []);

  const { userStatus, ledgerTransaction, loading } = localStates;
  if (loading) return <FreedomGifLoader />;

  if ((userStatus === 8 && ledgerTransaction === false && activeUser === true) || (rowNumber === getRowNumber() && onboardingCompleted === false)) {
    return <BusinessOnboardFee />;
  } else if ((userStatus === 8 && ledgerTransaction === true && activeUser === true) || (rowNumber === getRowNumber() && onboardingCompleted === false)) {
    return <DocumentVerificationPending />;
  }

  if (
    (userType === freedomUserType.Distributor && parentUserLeg !== null && parentUserId !== null && (onboardingCompleted === false || onboardingCompleted === 0) && activeUser === true) ||
    (rowNumber === getRowNumber() && onboardingCompleted === false)
  ) {
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        {/* <OnBoardMainScreen /> */}
        <OnBoardSelection />
      </Suspense>
    );
  }

  if (activeUser === false && onboardingCompleted === false) {
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        <InActiveUser />
      </Suspense>
    );
  }

  if (userType === freedomUserType.Trader && onboardingCompleted) return <Suspense fallback={<FreedomGifLoader />}><TraderPluginPage /></Suspense>;

  if (parentUserLeg === null && activeUser === true && onboardingCompleted === true && rowNumber !== getRowNumber()) {
    if (userType === freedomUserType.Distributor || userType === freedomUserType.Customer) {
      return (
        <Suspense fallback={<FreedomGifLoader />}>
          <ContactWithAffiliate />
        </Suspense>
      );
    }

    if (userType === freedomUserType.Trader) {
      return <p>Currntly this plugin is available on mobile application. please download our mobile app from google play store or app store.</p>;
    }
  }

  if (onboardingCompleted === true)
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        {/* <OnBoardFeeLoading /> */}
        <Main />
      </Suspense>
    );

  if (isValidInvitationCode === undefined) return <FreedomGifLoader />;

  if (isValidInvitationCode === true)
    return (
      <Suspense fallback={<FreedomGifLoader />}>
        <OnBoardSelection />
      </Suspense>
    );

  return (
    <Suspense fallback={<FreedomGifLoader />}>
      {/* <NotAccessible /> */}
      <OnBoardSelection />
    </Suspense>
  );
};

export default FlowControl;
