import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import { appInsights } from '../../../auth/appInsight';

const TypographyText = styled('p')(({ theme, color, textshadow, fontsize, fontweight, lineheight, textalign, padding, xllfontsize, xllineheight, margin, texttransform }) => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: fontweight || 500,
  fontSize: fontsize || '12px',
  lineHeight: lineheight || '24px',
  textAlign: textalign || 'center',
  color: color || theme.palette.text.primary,
  textShadow: textshadow || '',
  margin: margin || '0px 0px 0px 0px',
  padding: padding || 0,
  textTransform: texttransform || 'capitalize'
  // [theme.breakpoints.up('xll')]: {
  //   fontSize: '12px',
  //   lineHeight: '18px'
  // }
}));
const FreedomTypographyText = (props) => {
  const { children, color, textshadow, fontsize, xllfontsize, fontweight, lineheight, xllineheight, textalign, margintop, marginright,  texttransform, ...rest} = props;
  try {
    return (
      <TypographyText
        style={{ marginTop: margintop, marginRight: marginright }}
        color={color}
        fontsize={fontsize}
        fontweight={fontweight}
        lineheight={lineheight}
        xllfontsize={xllfontsize}
        xllineheight={xllineheight}
        textalign={textalign}
        textshadow={textshadow}
        texttransform={texttransform}
        {...rest}
      >
        {children}
      </TypographyText>
    );
  } catch (error) {
    appInsights.trackException({ error: new Error(error) });
  }
};

export default FreedomTypographyText;

FreedomTypographyText.propTypes = {
  color: PropTypes.string,
  fontsize: PropTypes.string,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.string,
  textAlign: PropTypes.string
};
