import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebarExpand: false,
  isSmallScreen: false,
  isMobileSidebar: false,
  favoriteBottomItems: [
    {
      id: 0,
      moduleId: '',
      moduleOrder: 1,
      isDelete: true
    },
    {
      id: 0,
      moduleId: '',
      moduleOrder: 2,
      isDelete: true
    },
    {
      id: 0,
      moduleId: '',
      moduleOrder: 3,
      isDelete: true
    },
    {
      id: 0,
      moduleId: '',
      moduleOrder: 4,
      isDelete: true
    }
  ],
  defaultPageId: 0,
  systemDefaultSetting: 1
};

const sideBarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    updateIsSidebarExpand: (state, action) => {
      state.isSidebarExpand = action.payload;
    },
    updateIsSmallScreen: (state, action) => {
      state.isSmallScreen = action.payload;
    },
    updateIsMobileSidebar: (state, action) => {
      state.isMobileSidebar = action.payload;
    },
    updateFavoriteBottomItems: (state, action) => {
      state.favoriteBottomItems = action.payload;
    },
    updateDefaultPageId: (state, action) => {
      state.defaultPageId = action.payload
    },
    updateSystemDefaultSetting: (state, action) => {
      state.systemDefaultSetting = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateIsSidebarExpand, updateIsSmallScreen, updateIsMobileSidebar, updateFavoriteBottomItems, updateDefaultPageId, updateSystemDefaultSetting } = sideBarSlice.actions;

export default sideBarSlice.reducer;
