import { CTraderAccountReconciliation } from '../../utils/tradingConfig';
import { getBaseUrl, getBasicUrl } from '../../utils/userConfig';

const baseUrl = getBaseUrl();
const basicUrl = getBasicUrl();
export const getUserPersonalIdentificationsApiUrl = (databaseId) => {
  return `${baseUrl}UserPersonalIdentifications?$filter=UserId eq @UserId &$top=1&$select=Givenname,Surname,Gender,PersonalIdentificationNumberMasked,PlaceOfBirth,DateOfBirth&$orderby=CreatedOn desc`;
  // return `${baseUrl}UserPersonalIdentifications?$filter=UserId eq ${databaseId} &$top=1&$select=Givenname,Surname,Gender,PersonalIdentificationNumber,PlaceOfBirth,DateOfBirth&$orderby=CreatedOn desc`;
};

export const getUserProfileApiUrl = (uniqueId) => {
  // return `${basseUrl}users?$filter=AadObjectId eq ${uniqueId}&$top=1&$select=Id,HasProfilePicture,AboutMe`

  return `${baseUrl}users?$filter=Id eq @UserId&$top=1&$select=Id,Givenname, Surname,HasProfilePicture,AboutMe, AutoPositionSide`;
  // return `${baseUrl}users?$filter=AadObjectId eq @AadObjectId&$top=1&$select=Id,Givenname, Surname,HasProfilePicture,AboutMe`;
  // return `${baseUrl}users?$filter=AadObjectId eq ${uniqueId}&$top=1&$select=Id,Givenname, Surname,HasProfilePicture,AboutMe`;
};

export const getUserProfileDateOfBirthApiUrl = (databaseId) => {
  return `${baseUrl}userpersonalidentifications?$filter=UserId eq @UserId&$top=1&$select=DateOfBirth&$orderby=CreatedOn desc`;
};

export const getBrokerTypeUrl = () => {
  return `${baseUrl}brokertypes`;
};

export const getBrokerConnectedLink = () => {
  return `${baseUrl}useraccountintegrations?$filter=platformTypeId eq 7`;
};
export const getnewBrokerConnectedLink = (id) => {
  return `${CTraderAccountReconciliation()}/api/FreedomMyPlan/UserAccountIntegrations?id=${id}`;
};

export const getUserBrokerUrl = () => {
  return `${baseUrl}userbrokers`;
};

//getDocument
export const getUserDocumentUrl = (documentType) => {
  return `${baseUrl}userdocuments?$filter=DocumentTypeId eq ${documentType}&$orderby=CreatedOn desc&$top=1`;
};

//delete account url
export const getAccountDeleteUrl = () => {
  return `${basicUrl}User/deleteuser()`;
};
//Auto leg selection API
export const getAutoLegSelection = (legSide) => {
  return `${basicUrl}data/Settings/SetPrefferedLegSide?legside=${legSide}`;
};

export const getDefualtPageUrl = () => {
  return `${baseUrl}UserPage/GetDefaultPage`
}

export const getDefaultPageByUserType = (userType) => {
  return `${baseUrl}UserPage/GetUserPagesByUserTypeID?usertypeid=${userType}`
}

export const saveDefaultPageDataUrl = (pageId, editableId, systemDefaultSetting) => {
  return `https://api-test.myfreedom.ae/api/v1/data/UserPage/SetDefaultPage?pageid=${pageId}&usersettingid=${editableId}&defaultpagepreference=${systemDefaultSetting}`
}